import { formatPhoneNumber } from './helpers/format';

const isAcquisition = [
  'get.dev.sky.co.nz',
  'get.sky.co.nz',
  'get.prelive.sky.co.nz',
  'localhost:3200',
  'localhost:3100',
  'getstarted',
].some(el => window?.location?.host.includes(el));
const defaultPhoneNumber = '09 525 5555';
const defaultICOMSPin = '1234';

const SKY_BROADBAND_T_AND_C_URL = process.env.REACT_APP_SKY_BROADBAND_T_AND_C_URL!;

const SKY_BROADBAND_KEY_THINGS_URL = process.env.REACT_APP_SKY_BROADBAND_KEY_THINGS_URL!;
const SKY_TV_KEY_THINGS_URL = process.env.REACT_APP_SKY_TV_KEY_THINGS_URL!;

const SKY_BROADBAND_EXIT_FEES = process.env.REACT_APP_SKY_BROADBAND_EXIT_FEES_URL!;
const SKY_BROADBAND_CALLUS_NUMBER = '09 525 5555' || defaultPhoneNumber; //default value needed as unit tests can't access envkey variables and this is used in formatPhoneNumber below
export const REACT_APP_KK_CUSTOMER_ID_EXPIRE_MS = parseInt(process.env.REACT_APP_KK_CUSTOMER_ID_EXPIRE_MS ?? '0');

export const GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_URL!;
export const BACKEND_ENDPOINT = process.env.REACT_APP_ENDPOINT!;

const summaryStrings = {
  packagesAndProducts: 'Packages & Products',
  specialOffer: 'Special Offer',
  checkout: 'Checkout',
  continue: 'Continue',
  totalMobile: 'Your First Monthly Charge',
  totalDesktop: 'Your First Monthly Charge',
  totalMobileCart: 'Your Monthly Charge',
  totalDesktopCart: 'Your Monthly Charge',
  addPromoCode: 'Enter Offer Code',
  enterPromoCode: 'Enter Offer Code',
  applyPromoCode: 'Apply',
  monthlyCharge: 'Ongoing monthly cost',
} as const;
export const frequency = ['none', 'weekly', 'fortnightly', 'monthly'];
export const unformattedTelPhoneNumber = SKY_BROADBAND_CALLUS_NUMBER;
const telPhoneNumber = formatPhoneNumber(SKY_BROADBAND_CALLUS_NUMBER, true) || defaultPhoneNumber;
const linkPhoneNumber = `<a class="text-blue-light underline" href="tel:${telPhoneNumber}">${telPhoneNumber}</a>`;

const PHONE_AREA_CODES = ['021', '022', '023', '024', '025', '027', '028', '029', '020'];

const messages = {
  productAdded: (productName: string) => `${productName} added`,
  productRemoved: (productName: string) => `${productName} removed`,
  productReplaced: (productAddedName: string, productReplacedName: string) =>
    `${productReplacedName} removed, ${productAddedName} added`,
  dependencyWarning: (childName: string, parentName: string) =>
    `Before you add ${childName}, you'll need to add ${parentName}`,
  noThanks: 'No thanks',
  oops: 'Opps. Sorry, something went wrong',
  removeCoupon: 'Remove Offer',
  removeProduct: 'Remove Product',
  addProduct: 'Add Product',
  areYouSure: 'Are you sure?',
  addASkyBoxToContinue: 'You need to add a Sky Box to continue',
  addASkyBox: 'You must add a Sky Box first',
  couponError: `Sorry, there was a problem applying your coupon. Please contact the Sky team on ${linkPhoneNumber}`,
  preventUpgrade: `Please contact the Sky Team on ${linkPhoneNumber} to upgrade your subscription.`,
  technicianAvailabilityProblem: `Once you proceed with the order, our Sky Team will contact you to confirm your installation date.`,
  technicianAvailabilityProblemCSRWillContactYou: 'Our Sky Team will contact you to confirm your installation date.',
  problemWithOrder: `Sorry, there was a problem submitting your order. Please try again. If the problem persists please contact the Sky team on ${linkPhoneNumber}`,
  freeProductPayInFull: (productName: string, price: number) =>
    `By adding back ${productName} to the cart, you will be paying $${price}.`,
  arrowInternetRequirement: "You'll need an internet connection to use your new Sky box, data usage will apply.",
};

const pageErrorMessage = {
  header: 'Snap!',
  lineOne: 'Looks like something went wrong',
  lineTwo: 'Try again later...',
};

const catIds = process.env.REACT_APP_KONAKART_CATEGORY_IDS;
const catIdsExcludedFromOneMonthOnUs = process.env.REACT_APP_CATEGORY_IDS_OMO_US_EXCLUDED;

//falls back to the development values if no value can be found
const categoryIds = catIds
  ? JSON.parse(catIds)
  : ({
      package: 31,
      box: 34,
      packageUpgrade: 36,
      special: 40,
      oneOffFee: 39,
      monthlyFee: 69,
      hindiChannels: 41,
      additionalCharges: 42,
      broadband: 45,
      broadbandServices: 46,
      broadbandOneOffFee: 47,
      broadbandDevices: 48,
      digitalDecoder: 51,
      bundle: 63,
      voice: 52,
      voiceAddons: 53,
      voiceCrossCountry: 54,
      discounts: 58,
      broadbandVouchers: 59,
      technicianFee: 55,
      broadbandTechnicianFee: 60,
      recording: 65,
    } as const);

const categoryIdsExcludedFromOneMonthOnUs = catIdsExcludedFromOneMonthOnUs
  ? JSON.parse(catIdsExcludedFromOneMonthOnUs)
  : [categoryIds.oneOffFee, categoryIds.technicianFee];

const productSkuIds = {
  starter: {
    primary: '202',
    customAttribute: null,
    label: '',
  },
  access: {
    primary: '122',
    customAttribute: null,
    label: '',
  },

  /* The old box in the new world. */
  skyBoxCharge: {
    primary: '620',
    customAttribute: null,
    label: '',
  },

  paceBox500GB: {
    primary: '600',
    customAttribute: null,
    label: '',
  },

  paceBox1TB: {
    primary: '610',
    customAttribute: null,
    label: '',
  },

  kaonBox500GBUpfront: {
    primary: '619',
    customAttribute: null,
    label: '',
  },

  paceBox500GBUpfront: {
    primary: '605',
    customAttribute: null,
    label: '',
  },

  kaonBox1TBUpfront: {
    primary: '617',
    customAttribute: null,
    label: '',
  },

  paceBox1TBUpfront: {
    primary: '611',
    customAttribute: null,
    label: '',
  },

  kaonBox1TB: {
    primary: '618',
    customAttribute: null,
    label: '',
  },

  /* The old boxes that we don't sell anymore. */
  legacyBoxCharge: {
    primary: '600,619,605,618,610,617,611',
    customAttribute: null,
    label: '',
  },

  skyBox: {
    primary: '620,615',
    customAttribute: '001,207,602,603',
    label: '',
  },

  mySky: {
    primary: '620,612',
    customAttribute: '001,207,602,603',
    label: '',
  },

  mySkyPlus: {
    primary: '618,612,614',
    customAttribute: '001,207,602,603',
    label: '',
  },

  arrowBox: {
    primary: '624',
  },

  arrowBoxBlack: {
    primary: '625',
  },

  arrowBoxOneOff: {
    primary: '643',
  },

  /* Only comes with 643. */
  arrowUpfrontBoxFee: {
    //primary: isAcquisition ? '830C' : '830',
    primary: '830',
    customAttribute: null,
  },

  arrowMonthly: {
    primary: '642',
  },

  arrowBoxRecording: {
    primary: '612',
  },
  upfrontRecording: {
    primary: '613',
  },

  /* 1TB Storage. */
  recording1TB: {
    primary: '614',
  },

  /* VOD Only. */
  noRecording: {
    primary: '615',
  },

  koanSkyBoxPlusRecording: {
    primary: '617',
  },

  koanSkyBoxRecording: {
    primary: '619',
  },

  skyPod: {
    primary: '616',
  },

  skyPodOneOffFee: {
    primary: '887',
    customAttribute: null,
  },

  // note: this only a temporary sky stream sku
  skyStream: {
    primary: '900',
  },

  paceSkyBox: {
    primary: '600,615',
    customAttribute: '001,207,602,603',
    label: '',
  },
  paceMySky: {
    primary: '600,612',
    customAttribute: '001,207,602,603',
    label: '',
  },
  paceMySkyPlus: {
    primary: '610,612,614',
    customAttribute: '001,207,602,603',
    label: '',
  },
  upFrontMySky: {
    primary: '619,613',
    customAttribute: '001,207,602,603',
    label: '',
  },
  upFrontMySkyPlus: {
    primary: '617,613',
    customAttribute: '001,207,602,603,612,614',
    label: '',
  },
  upFrontPaceMySky: {
    primary: '605,613',
    customAttribute: '001,207,602,603',
    label: '',
  },
  upFrontPaceMySkyPlus: {
    primary: '611,613',
    customAttribute: '001,207,602,603,612,614',
    label: '',
  },
  broadcastTier: {
    primary: '205',
    customAttribute: null,
    label: '',
  },
  broadbandForcedOccurrence: {
    primary: '001BB',
    customAttribute: null,
    label: '',
  },
  broadbandLightningFastWiFi: {
    primary: '101BB',
    customAttribute: null,
    label: 'Lightning Fast WiFi',
  },
  broadbandLightningFastWiFiBoost: {
    primary: '102BB',
    customAttribute: null,
    label: 'Lightning Fast WiFi Boost',
  },
  broadbandWifi100: {
    primary: '110BB',
    customAttribute: null,
    label: 'Speedy WiFi',
  },
  broadbandWifi100Boost: {
    primary: '111BB',
    customAttribute: null,
    label: 'Speedy WiFi Boost',
  },
  broadbandEssnFibre: {
    primary: '120BB',
    customAttribute: null,
    label: 'Fibre Starter',
  },
  broadbandEssnFibBoost: {
    primary: '121BB',
    customAttribute: null,
    label: 'Fibre Starter Boost',
  },
  broadbandPerfectInstall: {
    primary: '804BB',
    customAttribute: null,
    label: 'PerfectInstall',
  },
  skyRouter: {
    primary: '603BB',
    customAttribute: null,
    label: '',
  },
  meshOccurrence: {
    primary: '601BB',
    customAttribute: null,
  },
  meshDevice: {
    primary: '604BB',
    customAttribute: null,
    label: '',
  },
  broadbandSelfInstall: {
    primary: '801BB',
    customAttribute: null,
    label: 'SelfInstall',
  },
  broadbandBringYourOwnDevice: {
    primary: '602BB',
    customAttribute: null,
    label: 'BYOD',
  },
  broadbandStaticIP: {
    primary: '199BB',
    customAttribute: null,
    label: '',
  },
  summerSetIdentifier: {
    primary: '901BB',
    customAttribute: null,
    label: '',
  },
  summerSet: {
    primary: '119BB',
    customAttribute: null,
    label: '',
  },
  sport: {
    primary: '242',
    customAttribute: null,
    label: '',
  },
  entertainment: {
    primary: '238',
    customAttribute: null,
    label: '',
  },
  entertainmentLight: {
    primary: '112',
    customAttribute: null,
    label: '',
  },
  entertainmentPremium: {
    primary: '113',
    customAttribute: null,
    label: '',
  },
  entertainmentPlatinum: {
    primary: '114',
    customAttribute: null,
    label: '',
  },
  movies: {
    primary: '233',
    customAttribute: null,
    label: '',
  },
  soho: {
    primary: '260',
    customAttribute: null,
    label: '',
  },
  arts: {
    primary: '256',
    customAttribute: null,
    label: '',
  },
  countryTv: {
    primary: '258',
    customAttribute: null,
    label: '',
  },
  rialto: {
    primary: '232',
    customAttribute: null,
    label: '',
  },
  skyBoxOTC: {
    primary: '725',
    customAttribute: null,
    label: '',
  },
  mySkyOTC: {
    primary: '736',
    customAttribute: null,
    label: '',
  },
  mySkyPlusOTC: {
    primary: '736',
    customAttribute: null,
    label: '',
  },
  paperBilling: {
    primary: 'PAPERLESS',
    customAttribute: null,
    label: '',
  },
  broadbandSelfInstallOTC: {
    primary: '800BB',
    customAttribute: null,
    label: '',
  },
  multiroom: {
    primary: '207',
    customAttribute: null,
    label: '',
  },
  broadcastService: {
    primary: '927',
    customAttribute: null,
    label: '',
  },
  voice: {
    primary: '100TP',
    customAttribute: '001TP',
    label: '',
  },
  voiceSummerSet: {
    primary: '110TP',
    customAttribute: null,
    label: '',
  },
  voiceSummerSetOpt: {
    primary: '112TP',
    customAttribute: null,
    label: '',
  },
  voiceAustraliaCalls: {
    primary: '200TP',
    customAttribute: null,
    label: '',
  },
  voiceNationalCalls: {
    primary: '102TP',
    customAttribute: null,
    label: '',
  },
  voiceTop10Calls: {
    primary: '201TP',
    customAttribute: null,
    label: '',
  },
  voiceTop20Calls: {
    primary: '202TP',
    customAttribute: null,
    label: '',
  },
  broadbandStarterDiscountOneGig: {
    primary: '150BB',
  },
  broadbandStarterDiscountHundredMeg: {
    primary: '151BB',
  },
  voucherForcedOccurrence: {
    primary: '001SS',
  },
  voucherEligible: {
    primary: '101SS',
  },
  voucherActive: {
    primary: '102SS',
  },

  /* This is actually tech install no charge. */
  ArrowTechInstall: {
    primary: '837',
  },

  ArrowTechVisit: {
    primary: '838',
  },

  /* Pass New Sky Box self install if user doesn't want tech visit. */
  ArrowSelfInstall: {
    primary: '841',
  },

  otcBoxSwap: {
    primary: '728',
    customAttribute: null,
  },
  otcBoxSwapUpFront: {
    primary: '724',
    customAttribute: null,
  },

  premiumEntertainment: {
    primary: '128',
  },
  lightEntertainment: {
    primary: '127',
  },
  ultimateEntertainment: {
    primary: '129',
  },

  frontBookMySky: {
    primary: '166',
  },

  skyDigitalMusic: {
    primary: '250',
  },
} as const;

const catalogueTypeSKU = {
  backbook: '001',
  frontbook: '002',
};

const upgradableArrowBoxeskuIds = {
  ArrowBoxWithRecordingOneOff: {
    primary: '624,612,643,830',
  },
  ArrowBoxWithoutRecordingMonthly: {
    primary: '624,642',
  },
  ArrowBoxWithoutRecordingOneOff: {
    primary: '624,643,830',
  },
  ArrowBoxWithRecordingMonthly: {
    primary: '624,612,642',
  },
} as const;

const broadbandProvisioningSkus = {
  Provision100: '109BB',
  Provision1G: '100BB',
} as const;

const broadbandDiscounts = JSON.parse(process.env.REACT_APP_SKY_BROADBAND_DISCOUNTS ?? '[]');

const couponValidation = {
  min: 1,
  max: 64,
} as const;

const PPVOccurrenceCableTypes = ['SKYBO+', 'SKYHDI', 'SKYBOX', 'MYSKY+', 'MYSKY', 'ARROW', 'SKYPOD'];

const offerLinkLabel = 'Offer T&Cs';
const freeProductLinkLabel = (name: string): string => `${name} Offer T&Cs`;

const FIXED_ICOMS_LIMIT = 32;

const STAFF_ACCOUNT_TYPE = '9:SKY STAFF';

const STAFF_SERVICE_CODES = [
  '001',
  '202',
  '207',
  '232',
  '233',
  '238',
  '242',
  '250',
  '256',
  '257',
  '258',
  '260',
  '602',
  '603',
];

const BRAND = {
  DTH: 'Sky DTH',
  BROADBAND: 'Sky Broadband',
};

export const BUNDLES: { [key: string]: any } = {
  bb_pro_bundle: [
    productSkuIds.starter.primary,
    productSkuIds.broadbandSelfInstall.primary,
    productSkuIds.broadbandSelfInstallOTC.primary,
    productSkuIds.broadbandLightningFastWiFi.primary,
    productSkuIds.skyRouter.primary,
  ],
  bb_everyday_bundle: [
    productSkuIds.starter.primary,
    productSkuIds.broadbandSelfInstall.primary,
    productSkuIds.broadbandSelfInstallOTC.primary,
    productSkuIds.broadbandWifi100.primary,
    productSkuIds.skyRouter.primary,
  ],
  bb_starter_bundle: [
    productSkuIds.starter.primary,
    productSkuIds.broadbandSelfInstall.primary,
    productSkuIds.broadbandSelfInstallOTC.primary,
    productSkuIds.broadbandEssnFibre.primary,
    productSkuIds.skyRouter.primary,
  ],
  bb_pro: [
    productSkuIds.broadbandSelfInstall.primary,
    productSkuIds.broadbandSelfInstallOTC.primary,
    productSkuIds.broadbandLightningFastWiFi.primary,
    productSkuIds.skyRouter.primary,
  ],
  bb_everyday: [
    productSkuIds.broadbandSelfInstall.primary,
    productSkuIds.broadbandSelfInstallOTC.primary,
    productSkuIds.broadbandWifi100.primary,
    productSkuIds.skyRouter.primary,
  ],
  starter: [productSkuIds.starter.primary],
  movies: [productSkuIds.movies.primary],
  sports: [productSkuIds.sport.primary],
  ents: [productSkuIds.entertainment.primary],
  soho: [productSkuIds.soho.primary],
  arts: [productSkuIds.arts.primary],
  country: [productSkuIds.countryTv.primary],
};

const CATEGORY = {
  DTH: 'DTH',
  BROADBAND: 'Broadband',
};

const PACKAGE_TYPE = {
  DTH: 'DTH',
  BROADBAND: 'Broadband',
  DTH_BROADBAND: 'DTH + Broadband Bundle',
};

const PAYMENT_METHOD = {
  CREDIT: 'Credit',
  DEBIT: 'Debit',
};

const AFFILIATION = {
  ACQ: 'acquisition',
  UPG: 'upgrade',
};

const SPAs = {
  CART: 'Cart',
  MYACCOUNT: 'MyAccount',
  BROADBAND: 'Broadband',
};

const ELIGIBILITY_FOR = {
  Arrow_Loyalty_Discount: 'Arrow_Discount',
  VTV_Migration: 'VTV_Migration',
  MySky_Upgrade_1: 'MySky_Upgrade_1',
};

const ELIGIBILITY_ACQUISITION_TYPE = {
  VTV_Standalone: 'VTV_Standalone',
  Standard_Acquisition: 'Standard_Aquisition',
};

const APP_ENVIRONMENT_VALUES = {
  dev: 'dev',
  prelive: 'prelive',
  prod: 'prod',
};

const links = {
  termsOfUse: 'https://www.sky.co.nz/terms-of-use',
  ddForm: 'https://static.sky.co.nz/sky/docs/dd/direct-debit_form.pdf',
  privacyPolicy: 'https://www.sky.co.nz/privacy-policy',
  bbTerms: SKY_BROADBAND_T_AND_C_URL,
  bbKeyThings: SKY_BROADBAND_KEY_THINGS_URL,
  tvKeyThings: SKY_TV_KEY_THINGS_URL,
  bbExitFees: SKY_BROADBAND_EXIT_FEES,
  conditionsOfAuthority: 'https://static.sky.co.nz/sky/docs/bills/conditions_of_authority.pdf',
  earlyTerminationFee: 'https://help.sky.co.nz/s/article/sky-article-299',
} as const;

const hardcodedTermsAndConditions = [
  {
    category: categoryIds.broadband,
    termsAndConds: `On a 12 month contract. <a target="blank" href="${links.bbTerms}" class="underline cursor-pointer sky-text-daylight">T&Cs</a> and <a target="blank" href="${links.bbExitFees}" class="underline cursor-pointer sky-text-daylight">exit fees</a> apply.`,

    reactNativeTermsAndConds: [
      {
        text: 'On a 12 month contract. ',
      },
      {
        text: 'T&Cs',
        link: links.bbTerms,
      },
      {
        text: ' and ',
      },
      {
        text: 'exit fees',
        link: links.bbExitFees,
      },
      {
        text: ' apply',
      },
    ],
  },
  {
    category: categoryIds.broadband,
    termsAndConds: `No fixed term contract. <a target="blank" href="${links.bbTerms}" class="underline cursor-pointer sky-text-daylight">T&Cs</a> apply.`,
    reactNativeTermsAndConds: [
      {
        text: 'No fixed term contract. ',
      },
      {
        text: 'T&Cs',
        link: links.bbTerms,
      },
      {
        text: ' apply',
      },
    ],
  },
  {
    sku: productSkuIds.meshDevice.primary,
    termsAndConds:
      'Check <a target="blank" href="https://help.sky.co.nz/s/article/How-do-I-know-if-I-need-a-Booster" class="underline cursor-pointer sky-text-daylight">here</a> for our recommendation',
    reactNativeTermsAndConds: [
      {
        text: 'Check ',
      },
      {
        text: 'here',
        link: 'https://help.sky.co.nz/s/article/How-do-I-know-if-I-need-a-Booster',
      },
      {
        text: ' for our recommendation',
      },
    ],
  },
] as any[];

const EarlyTerminationTC = `On a 12 month contract. <a target="blank" href="${links.earlyTerminationFee}" class="underline cursor-pointer sky-text-daylight">Early termination fees</a> apply.`;
const cableCouponCodeOnA12MonthContract = process.env.REACT_APP_CABLE_12_MONTH_CONTRACT_CAMPAIGN!;
const dataCouponCodeOnA12MonthContract = process.env.REACT_APP_DATA_12_MONTH_CONTRACT_CAMPAIGN!;
const dataCouponCodeNoContract = process.env.REACT_APP_DATA_NO_CONTRACT!;
const NON_FIXED_TERM_BB_1_MONTH_ON_US_COUPON_ACQ = '09033BB';
const NON_FIXED_TERM_BB_1_MONTH_ON_US_COUPON = '09035BB';

// Broadband Credit Campaigns:
// 09044BB – this is without contract for existing DTH customers.
// 09046BB – this is with contract for non DTH customers.
// New Sky Box Credit:
// 01280 - For acquistion
const CreditCampaignCodes = {
  BROADBAND: {
    //commented old credit coupons && added BB new coupo-sns
    // CREDIT_BB_DTH: '09044BB',
    // CREDIT_BB_ONLY: '09046BB',
    CREDIT_BB_DTH: '09060BB',
    CREDIT_BB_ONLY: '09061BB',
  },
  BB_SPEED_UPGRADE: {
    //Free Speed Upgrade Bundled customers $10 discount for Fibre pro
    BB_FP_SPEED_UPGRADE: '09074BB',
    //Free Speed Upgrade Bundled customers $10 discount for Fibre pro everyday
    BB_FP_BOOST_SPEED_UPGRADE: '09075BB',
  },
  NEW_SKY_BOX: {
    UPFRONT_ACQUISITION: '01280',
  },
};

//black friday
const BFriday = {
  BFridayUpgarade: {
    cCode: '04955',
  },
};
// Add this OTC service codes to payload if one of the Credit Campaign Code
// is added AND the matching product is present
const CreditOTCCode = {
  BROADBAND_1G: '710BB',
  BROADBAND_100: '711BB',
  NEW_SKY_BOX_UPFRONT: '700C',
};

const boxCategories = [categoryIds.box, categoryIds.recording];

export const couponCodes = {
  oneMonthOnUs: '01226',
  oneMonthOnUsNoContract: '01268',
  oneMonthOnUsWOSport: '01276',
  oneMonthOnUsWithSport: '01277',
  offer50WOShohoHindi: '01303',
  offer50WShohoHindi: '01293',
  bundleCoupon_dth: '01302',
  bundlecoupon_BB: '09059BB',
  bundlecoupon_soho_Hindi: '01310',
};

export const NSB_SKYPOD_MONTHLY_AND_ONEOFF_FEE_ON_US = '04998';

/* Coupon codes like these should be in envkey to avoid redeployment on change. */
export const boxCampaigns = process.env.REACT_APP_BOX_CAMPAIGNS ? JSON.parse(process.env.REACT_APP_BOX_CAMPAIGNS) : [];

const packageCategories = [
  categoryIds.package,
  categoryIds.packageUpgrade,
  categoryIds.special,
  categoryIds.hindiChannels,
];

const voiceCategories = [categoryIds.voice, categoryIds.voiceAddons, categoryIds.voiceCrossCountry];

const broadbandCategories = [
  categoryIds.broadband,
  categoryIds.broadbandServices,
  categoryIds.broadbandOneOffFee,
  categoryIds.broadbandDevices,
  categoryIds.broadbandTechnicianFee,
];

const includedProductServiceCodes: string[] = [
  productSkuIds.skyBox.primary,
  productSkuIds.skyRouter.primary,
  productSkuIds.meshDevice.primary,
];

const multiQuantityCategories: number[] = [
  categoryIds.box,
  categoryIds.multiroom,
  categoryIds.monthlyFee,
  categoryIds.recording,
];

const productsHiddenInBillsAndPayments: string[] = [productSkuIds.noRecording.primary];

const voucherCategories = [categoryIds.broadbandVouchers];

const broadbandCoupons = process.env.REACT_APP_BROADBAND_COUPONS
  ? JSON.parse(process.env.REACT_APP_BROADBAND_COUPONS)
  : {};
const silentCampaignCodes = process.env.REACT_APP_SILENT_CAMPAIGNS
  ? JSON.parse(process.env.REACT_APP_SILENT_CAMPAIGNS)
  : [];

const summerSetCampaignCode = ['09070BB', '09071BB'];
const priceHoldCampaignCodes = ['09028BB', '09029BB', '09030BB', '09031BB', '09032BB', '09023BB', '09055BB'];

const broadbandCouponsByPrivacyCode = process.env.REACT_APP_BROADBAND_PRIVACY_CODE_COUPONS
  ? JSON.parse(process.env.REACT_APP_BROADBAND_PRIVACY_CODE_COUPONS)
  : {};

const vodafoneCodes = ['966', '977'];
const iglooCodes = ['08695', '08696', '08697', '08698', '08785', '08786', '08787'];
const digiRentalCodes = ['205'];
/* Decoder provisioning codes for adding multiroom. */
const additionalDecoderCodes = ['001', '602', '603'];
/* Sky Pod device is not compatible with this services */
const decoderHDCompatibilityCodes = ['602', '603'];

export {
  summaryStrings,
  messages,
  categoryIds,
  productSkuIds,
  pageErrorMessage,
  couponValidation,
  offerLinkLabel,
  freeProductLinkLabel,
  FIXED_ICOMS_LIMIT,
  telPhoneNumber,
  linkPhoneNumber,
  PHONE_AREA_CODES,
  upgradableArrowBoxeskuIds,
  PPVOccurrenceCableTypes,
  BRAND,
  CATEGORY,
  PACKAGE_TYPE,
  PAYMENT_METHOD,
  AFFILIATION,
  SPAs,
  ELIGIBILITY_FOR,
  ELIGIBILITY_ACQUISITION_TYPE,
  APP_ENVIRONMENT_VALUES,
  links,
  hardcodedTermsAndConditions,
  STAFF_ACCOUNT_TYPE,
  STAFF_SERVICE_CODES,
  boxCategories,
  packageCategories,
  voiceCategories,
  broadbandCategories,
  voucherCategories,
  broadbandCoupons,
  broadbandCouponsByPrivacyCode,
  broadbandProvisioningSkus,
  silentCampaignCodes,
  summerSetCampaignCode,
  priceHoldCampaignCodes,
  includedProductServiceCodes,
  productsHiddenInBillsAndPayments,
  vodafoneCodes,
  iglooCodes,
  broadbandDiscounts,
  defaultICOMSPin,
  digiRentalCodes,
  EarlyTerminationTC,
  cableCouponCodeOnA12MonthContract,
  dataCouponCodeOnA12MonthContract,
  dataCouponCodeNoContract,
  categoryIdsExcludedFromOneMonthOnUs,
  additionalDecoderCodes,
  decoderHDCompatibilityCodes,
  NON_FIXED_TERM_BB_1_MONTH_ON_US_COUPON,
  NON_FIXED_TERM_BB_1_MONTH_ON_US_COUPON_ACQ,
  multiQuantityCategories,
  catalogueTypeSKU,
  CreditCampaignCodes,
  CreditOTCCode,
  BFriday,
};

export const LIFERAY_URL = process.env.REACT_APP_LIFERAY_URL!;
export const SALESFORCE_CHART_SCRIPT_MYACCOUNT = process.env.REACT_APP_SALESFORCE_CHAT_SCRIPT_MYACCOUNT!;
export const SALESFORCE_CHART_SCRIPT_CART = process.env.REACT_APP_SALESFORCE_CHAT_SCRIPT_CART!;
export const BROADBAND_PRIVACY_CODES = process.env.REACT_APP_BROADBAND_PRIVACY_CODES!;
export const POPULAR_SERVICE_PROVIDERS = (process.env.REACT_APP_POPULAR_SERVICE_PROVIDERS || '').split(',');

/* CART. */
export const ICE_URL = process.env.REACT_APP_ICE_URL!;

/** Channel logo list used in Liferay as well. */
export const CHANNEL_JSON_CONFIG_URL = 'https://static.sky.co.nz/sky/json/channel_accordion_channel_logo_list.json';
export const CHANNELS_JSON_CONFIG_URL = 'https://skywebconfig.msl-prod.skycloud.co.nz/sky/json/channels.prod.json';

export const SPLIT_AUTHORIZATION_KEY = process.env.REACT_APP_SPLIT_AUTHORIZATION_KEY!;
export const SPLIT_KEY = 'skyweb';
export let SPLITIO_KEY = {
  MAINTENANCE_MODE_TVGUIDE: 'MAINTENANCE_MODE_TVGUIDE',
  MAINTENANCE_MODE_MYACCOUNT: 'MAINTENANCE_MODE_MYACCOUNT',
  MAINTENANCE_MODE_CART: 'MAINTENANCE_MODE_CART',
  MAINTENANCE_MODE_BROADBAND: 'MAINTENANCE_MODE_BROADBAND',
  MAINTENANCE_MODE_ICE: 'MAINTENANCE_MODE_ICE',
  SKYWEB_LIVECHAT_MYACCOUNT: 'SKYWEB_LIVECHAT_MYACCOUNT',
  SKYWEB_LIVECHAT_CART: 'SKYWEB_LIVECHAT_CART',
  SKYWEB_VOICE_DIRECTORY_LISTING: 'SKYWEB_VOICE_DIRECTORY_LISTING',
  SKYWEB_VOICE_FOR_EXISTING_BB: 'SKYWEB_VOICE_FOR_EXISTING_BB',
  SKY_WEB_SALESFORCE_CHAT_MYACCOUNT: 'SKY_WEB_SALESFORCE_CHAT_MYACCOUNT',
  SKY_WEB_SALESFORCE_CHAT_CART: 'SKY_WEB_SALESFORCE_CHAT_CART',
  SKY_WEB_SALESFORCE_CHAT_BROADBAND: 'SKY_WEB_SALESFORCE_CHAT_BROADBAND',
  SKYWEB_ORDER_TRACKER_NEW: 'SKYWEB_ORDER_TRACKER_NEW',
  SKYWEB_DIGITAL_RENTAL_UPGRADE: 'SKYWEB_DIGITAL_RENTAL_UPGRADE',
  SKYWEB_TRACKER_RESCHEDULE: 'SKYWEB_TRACKER_RESCHEDULE',
  SKYWEB_TECHNICIAN_LOOKUP: 'SKYWEB_TECHNICIAN_LOOKUP',
  SKYWEB_REFER_FRIEND_ENABLED: 'SKYWEB_REFER_FRIEND_ENABLED',
  SKYWEB_BROADBAND_ONLY_UPGRADE: 'SKYWEB_BROADBAND_ONLY_UPGRADE',
  SKYWEB_BROADBAND_CHANGING_PLANS: 'SKYWEB_BROADBAND_CHANGING_PLANS',
  SKYWEB_BROADBAND_AND_TV_ACQUISTION: 'SKYWEB_BROADBAND_AND_TV_ACQUISTION',
  SKYWEB_ENABLE_CREDIT_CHECK: 'SKYWEB_ENABLE_CREDIT_CHECK',
  SKYWEB_ALLOW_MARKETING_MESSAGE: 'SKYWEB_ALLOW_MARKETING_MESSAGE',
  SKYWEB_COUPON_TOAST: 'SKYWEB_COUPON_TOAST',
  SKYWEB_ONE_MONTH_ON_US: 'SKYWEB_ONE_MONTH_ON_US',
  SKYWEB_BB_MULTIROOM: 'SKYWEB_BB_MULTIROOM',
  SKYWEB_USE_ORDER_PROCESSOR_ENDPOINT: 'SKYWEB_USE_ORDER_PROCESSOR_ENDPOINT',
  SKYWEB_ALLOW_DOWNGRADES: 'SKYWEB_ALLOW_DOWNGRADES',
  SKYWEB_OVERRIDE_PRODUCT_INFO_FROM_KONAKART: 'SKYWEB_OVERRIDE_PRODUCT_INFO_FROM_KONAKART',
  SKYWEB_GETSKY_TO_ICE: 'SKYWEB_GETSKY_TO_ICE',
  SKYWEB_SHOW_BILLING_FOR_VTV: 'SKYWEB_SHOW_BILLING_FOR_VTV',
  SKYWEB_SHOW_NEW_CUST_PREF: 'SKYWEB_SHOW_NEW_CUST_PREF',
  SKYWEB_SKY_PRICE_CHANGE_POPUP: 'SKYWEB_SKY_PRICE_CHANGE_POPUP',
  SKYWEB_MULTIROOM_ADD: 'SKYWEB_MULTIROOM_ADD',
  SKYWEB_MULTIROOM_UPGRADE: 'SKYWEB_MULTIROOM_UPGRADE',
  SKYWEB_PRIMARYBOX_UPGRADE: 'SKYWEB_PRIMARYBOX_UPGRADE',
  SKYWEB_IDENTIFY_DEVICE: 'SKYWEB_IDENTIFY_DEVICE',
  SKYICE_MULTIROOM_ADD: 'SKYICE_MULTIROOM_ADD',
  DISABLE_MY_SKY_UPGRADES: 'DISABLE_MY_SKY_UPGRADES',
  SKYWEB_NEW_SKY_BOX: 'SKYWEB_NEW_SKY_BOX',
  SKYWEB_VTV_MIGRATION: 'SKYWEB_VTV_MIGRATION',
  SKYWEB_VTV_UPGRADE: 'SKYWEB_VTV_UPGRADE',
  SKYWEB_VTV_ARROW_ROI: 'SKYWEB_VTV_ARROW_ROI',
  SKYWEB_VTV_DART_PREORDER: 'SKYWEB_VTV_DART_PREORDER',
  ARROW_ACQUISITION: 'ARROW_ACQUISITION',
  REDIRECT_BB_BUNDLES_TO_ICE: 'REDIRECT_BB_BUNDLES_TO_ICE',
  MAMA_FORCE_UPDATE: 'MAMA_FORCE_UPDATE',
  NSB_REMOTE_RECORDING: 'NSB_REMOTE_RECORDING',
  MAMA_SF_CHAT: 'MAMA_SF_CHAT',
  SKYWEB_25_OFF_CAMPAIGN: 'SKYWEB_25_OFF_CAMPAIGN',
  // show and hide all content related to New Sky Box customer satisfaction guarantee
  SKYWEB_NEW_SKY_BOX_CSG: 'SKYWEB_NEW_SKY_BOX_CSG',

  SKYWEB_BB_ONE_MONTH_ON_US_ON: 'SKYWEB_BB_ONE_MONTH_ON_US_ON',
  //control BB TVC video
  SKYWEB_BB_TVC: 'SKYWEB_BB_TVC',
  //control Details(Explore) page on quiz
  SKY_QUIZ_HIDE_EXPLORE: 'SKY_QUIZ_HIDE_EXPLORE',

  /* Whether Sky Pod will be available for all (not just VTV) for UPRADE & ACQUISITION. */
  SKYWEB_SKY_POD_FOR_ALL: 'SKYWEB_SKY_POD_FOR_ALL',
  SKYWEB_SKY_POD_FOR_MY_ACCOUNT: 'SKYWEB_SKY_POD_FOR_MY_ACCOUNT',
  SKYWEB_BROADBAND_NEWPRICE: 'SKYWEB_BROADBAND_NEWPRICE',
  SKYWEB_ARROW_UPGRADE_TECHNICIAN: 'SKYWEB_ARROW_UPGRADE_TECHNICIAN',
  /* Paperless bill charge price increase copy */
  SKYWEB_PAPERLESS_PRICE_INCREASE_REVEAL: 'SKYWEB_PAPERLESS_PRICE_INCREASE_REVEAL',

  SKYWEB_BB_CREDIT_OFFER: 'SKYWEB_BB_CREDIT_OFFER',

  //splitIO flag_key's for NSB & POD
  SKYWEB_NSB_PPV_ENABLE: 'SKYWEB_NSB_PPV_ENABLE',
  SKYWEB_POD_PPV_ENABLE: 'SKYWEB_POD_PPV_ENABLE',

  /* Make Arena event booking controlled by a feature flag */
  SKYWEB_SHOW_SKY_ARENA: 'SKYWEB_SHOW_SKY_ARENA',

  /* Handle NSB Offer competition text */
  SKYWEB_SHOW_PROMO_COMPETITION: 'SKYWEB_SHOW_PROMO_COMPETITION',
  SKYWEB_ARROW_CREDIT_OFFER: 'SKYWEB_ARROW_CREDIT_OFFER',

  /* Sky Reward. */
  SKYWEB_SKY_REWARD: 'SKYWEB_SKY_REWARD',

  /* Sky Sport Reward */
  SKYWEB_SKY_SPORT_REWARD: 'SKYWEB_SKY_SPORT_REWARD',

  /* Sky BB Speed Upgrade */
  SKYWEB_BB_SPEED_UPGRADE: 'SKYWEB_BB_SPEED_UPGRADE',

  /* Used in Box Modal and Multiroom Modal */
  SKYWEB_SHOW_DELAYED_ARROW_INSTALLATION: 'SKYWEB_SHOW_DELAYED_ARROW_INSTALLATION',
  SKYWEB_POD_UPGRADE_TO_ARROW: 'SKYWEB_POD_UPGRADE_TO_ARROW',

  ENABLE_SURVICATE: 'ENABLE_SURVICATE',
  /* Send Replacement Button*/
  FAULT_SWAP_REPLACEMENT: 'FAULT_SWAP_REPLACEMENT',
  /* To Allow Pace Box in Fault Swap*/
  FAULT_SWAP_ENABLE_PACEBOX: 'FAULT_SWAP_ENABLE_PACEBOX',
  /* To Show Black Friday Banner*/
  SKYWEB_BLACK_FRIDAY_BANNER: 'SKYWEB_BLACK_FRIDAY_BANNER',
  /* To Show Half Price Banner*/
  SKYWEB_HALF_PRICE_BANNER: 'SKYWEB_HALF_PRICE_BANNER',
  /*verify account in order confirmation*/
  SKYWEB_ORD_CNFRM_MAIL_BLOCK: 'SKYWEB_ORD_CNFRM_MAIL_BLOCK',
  /* To Hide monthly and one off charge */
  SKYWEB_ACQ_HIDE_MNTH_ONEOF_CHRGE: 'SKYWEB_ACQ_HIDE_MNTH_ONEOF_CHRGE',

  SKYWEB_DISCOVER_PAGE: 'SKYWEB_DISCOVER_PAGE',

  /* To hide  running total displayed on bottom of the acquisition page (mobile screen) */
  SKYWEB_MOBILE_RUNNINGTOTAL: 'SKYWEB_MOBILE_RUNNINGTOTAL',

  /* To hide or show bundle offer banner */
  SKYWEB_BUNDLE_OFFER_BANNER: 'SKYWEB_BUNDLE_OFFER_BANNER',
};

export const CheckoutStates = {
  DETAILS: 'details',
  BROADBAND: 'broadband',
  TV: 'tv',
  VOICE: 'voice',
  REVIEW: 'review',
  DELIVERY_DETAILS: 'delivery-details',
} as const;

export const BB_VOUCHER_IMG = {
  MOBILE: 'https://static.sky.co.nz/sky/broadband/img/disney-banner/SkyBB_Disney_Offer_End_Date_Updated.jpg',
  DESKTOP: 'https://static.sky.co.nz/sky/broadband/img/disney-banner/SkyBB_Disney_Offer_End_Date_Updated.jpg',
};

export const ORDER_ERRORS = {
  RETURNING_CUSTOMER: 'RETURNING_CUSTOMER',
};

export const ORDER_ERROR_MSGS = {
  RETURNING_CUSTOMER: `Looks like something unexpected happened with your order. Please contact one of our friendly Customer agents who'll be keen to help you.  ${linkPhoneNumber}`,
};

export const VTVOccurrenceTypes = ['HHOCC', 'IPTV'];

export enum VTVMigrationType {
  STANDALONE = 'STANDALONE',
  TRANSFER = 'TRANSFER',
}

export enum VTVMigrationTypeCode {
  //Migrated Co 10 customers
  TRANSFER = '997',
  //Signed up standalone customers
  STANDALONE = '998',
}

export const DartEligibilityCode = {
  Discount10: ['04903', '04904'],
  Discount20: ['04914', '04915'],
};

export const DartMigrationCallCode = '70';

export const NSB_ROI_COUPON = '04945';
export const NSB_NON_REWARD_COUPON = '04913';

export enum SKY_REWARD_TEMPLATE_TYPE {
  REWARD,
  ROI,
  NON_REWARD,
}

// NSB Promo Competition details - Hardcoding as requested - Start
export const NSB_SKY_REWARDS_LOZENGE_TEXT = 'Sky Rewards Offer';
export const TANDC_URL = 'https://www.sky.co.nz/competition-terms';
// NSB Promo Competition details - Hardcoding as requested - End

//All fusion box SKUs:
export const fusionSKU = ['620', '600', '618', '610', '619', '605', '617', '611', '612'];
export const errorScreenMsg = [
  {
    header: 'Oops!',
    paragraph: 'Something went wrong with our system loading this page, but we’re looking into it! ',
    para2:
      'The good news is we’ve received your order and will send you an email receipt shortly. For anything else please contact our friendly Sky Crew here.',
  },
];
export const errorScreenLinkTrext = 'Sky Crew here';
export const skyCrewContactlinks = 'https://help.sky.co.nz/s/contactsupport';
export const removeCacheCoupons = [
  '01277',
  '01281',
  '01282',
  '01287',
  '01288',
  '01289',
  '01292',
  '01296',
  '09044BB',
  '09046BB',
  '09056BB',
  '09057BB',
  '01298',
  '01299',
  '04952',
];
